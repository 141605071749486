<template>
  <div>
    <div class="main-header">
      <div class="title">时间币流水查询</div>
    </div>
    <div class="main-main">
      <a-card>
         <a-tabs type="card" @change="go">
          <a-tab-pane key="1" tab="个人"></a-tab-pane>
          <a-tab-pane key="2" tab="机构"></a-tab-pane>
        </a-tabs>
        <PageList key="1" v-if="flag === '1'" type="1" />
        <PageList key="2" v-else type="2" />
      </a-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PageList from './components/List.vue'
export default {
  components: {
    PageList
  },
  data() {
    return {
      flag: '1',
    };
  },
  methods: {
    go(e) {
      this.flag = e;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
</style>