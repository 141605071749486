<template>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <div class="search-row">
            <div class="search-option">
              <span class="label">类型</span>
              <a-select
                allowClear
                placeholder="请选择类型"
                class="search-select"
                v-model="searchType"
                :options="allTypes"
              >
              </a-select>
            </div>
            <!-- <div class="search-option">
              <span class="label">年份</span>
              <a-date-picker
                mode="year"
                :value="searchOptions.year"
                format="YYYY"
                :open="open"
                @panelChange="selectYear"
                @openChange="openChange"
                placeholder="请选择年份"
                @change="ee"
                :allowClear="false"
              ></a-date-picker>
            </div>
            <div class="search-option">
              <span class="label">月份</span>
              <a-select
                placeholder="请选择月份"
                :options="monthList"
                class="search-select"
                v-model="searchOptions.month"
                allowClear
              >
              </a-select>
            </div> -->
            <div class="search-option">
              <span class="label">开始日期</span>
              <a-date-picker
                style="flex: 1"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                v-model="searchOptions.startTime"
              ></a-date-picker>
            </div>
            <div class="search-option">
              <span class="label">结束日期</span>
              <a-date-picker
                style="flex: 1"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                v-model="searchOptions.endTime"
              ></a-date-picker>
            </div>
            <div class="search-option" v-if="type === '2'">
              <span class="label">所属组织</span>
              <a-tree-select
                allowClear
                class="search-select"
                v-model="searchOptions.groupId"
                :tree-data="groupTree"
                placeholder="请选择所属组织"
              >
              </a-tree-select>
            </div>
            <div v-else class="search-option">
            <span class="label">手机号</span>
            <a-input
              placeholder="请输入手机号"
              v-model="searchOptions.phone"
              allowClear
            ></a-input>
          </div>
            <div class="empty">
              <a-button type="primary" @click="searchByOptions" icon="search"
                >搜索</a-button
              >
            </div>
            <!-- <div class="empty"></div>
            <div class="empty"></div> -->
          </div>
        </div>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns"></div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>

        <div class="stati-box">
            <div>
                <span>支出：</span>
                    <span style="color: green">{{moneyOut}}</span>
            </div>
          <div>
            <span>收入：</span>
            <span style="color: red">{{moneyIn}}</span>
          </div>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
          >
            <template #name="row">
              <span>{{setupName(row)}}</span>
            </template>
            <template #timeCoin="row">
              <span :style="`color:${row.isIncome ? 'green' : 'red'}`">
                <span>{{row.isIncome?'-':'+' }}</span> {{ row.timeMoneyCount }}
              </span>
            </template>
            <template #type="text">
              <span>{{text |typeFilter}}</span>
            </template>
          </a-table>
          <!-- <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :scroll="{ x: 10 }"
          >
          </a-table> -->
        </div>
      </a-card>
    </div>
</template>

<script>
import { getRecordList, getGroupRecordList } from "@/network/api";
import Pagination from "../../components/Pagination";
import { mapState } from "vuex";
export default {
  components: {
    Pagination,
  },
  props: {
    type: {
        type: String,
        default: '1',  // 1: 个人 2: 机构
    }
  },
  data() {
    return {
      open: false,
      submitDisable: false,
      total: 0,
      selectedRowList: [],
      selectedRowKeys: [],
      tableLoading: true,
      tableData: [],
      searchOptions: {
        page: 1,
        pageSize: 20,
        startTime: undefined,
        endTime: undefined,
        phone: undefined,
        // year: new Date().getFullYear() + "",
        // month:null
      },
      moneyIn: 0, // 收入
      moneyOut: 0, // 支出
      searchType: undefined,
      // 1-商品兑换2-转账3-系统时间币4-其他5-参加活动
      allTypes: [{label: '商品兑换', value: 1}, {label: '转账', value: 2}, {label: '系统时间币', value: 3}, {label: '其他', value: 4}, {label: '参加活动', value: 5}]
    };
  },
  methods: {
    // 组装原因
    setupName(record) {
      // timeMoneyType 时间币类型, 1-商品兑换2-转账3-系统时间币4-其他5-参加活动
      switch(Number(record.timeMoneyType)) {
        case 1:
          return `"${record.name}"兑换了"${record.recordName}"`
        case 2:
          return `"${record.name}"进行了${record.isIncome ? '转账': '入账'}`
        case 3:
          return '系统时间币'
        case 4: 
          return record.mark || '其他'
        case 5:
          return `"${record.name}"参加了"${record.recordName}"`
        default: 
        return record.mark || '其他'
      }
    },
    // ee() {
    //   this.$set(this.searchOptions, "year", null);
    // },

    // openChange(e) {
    //   if (e) {
    //     this.open = true;
    //   } else {
    //     this.open = false;
    //   }
    // },

    // selectYear(v) {
    //   this.searchOptions.year = v.format('YYYY');
    //   this.open = false;
    // },
    async getRecordList(
      params = {
        page: 1,
        pageSize: 20,
        // year: new Date().getFullYear() + "",
      }
    ) {
      this.selectedRowList = [];
      this.selectedRowKeys = [];
      this.tableLoading = true;
      if (params.startTime && params.startTime.length === 10) {
        params.startTime = `${params.startTime} 00:00:00`
      }
      if (params.endTime && params.endTime.length === 10) {
        params.endTime = `${params.endTime} 23:59:59`
      }
      const { data } = await (this.type === '1' ?  getRecordList(params) : getGroupRecordList(params));
      this.total = data.total;
      this.tableData = data.content.map((item) => {
        return item;
      });
      this.total = data.total;
      this.moneyOut = data.out;
      this.moneyIn = data.in;
      this.tableLoading = false;
    },

    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getRecordList(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getRecordList(this.searchOptions);
    },
    refresh() {
      this.getRecordList();
    },
    searchByOptions() {
      this.searchOptions.page = 1;
      this.searchOptions.timeMoneyType = this.searchType;
      this.getRecordList(this.searchOptions);
    },
    onSelectChange(e) {
      this.selectedRowKeys = e;
      this.selectedRowList = [];
      this.selectedRowKeys.forEach((item) => {
        this.selectedRowList.push(this.tableData[item].id);
      });
    },
  },

  created() {
    this.getRecordList();
  },
  computed: {
    ...mapState(["groupTree"]),
    monthList() {
      return [
        {
          title: "1",
          value: "1",
        },
        {
          title: "2",
          value: "2",
        },
        {
          title: "3",
          value: "3",
        },
        {
          title: "4",
          value: "4",
        },
        {
          title: "5",
          value: "5",
        },
        {
          title: "6",
          value: "6",
        },
        {
          title: "7",
          value: "7",
        },
        {
          title: "8",
          value: "8",
        },
        {
          title: "9",
          value: "9",
        },
        {
          title: "10",
          value: "10",
        },
        {
          title: "11",
          value: "11",
        },
        {
          title: "12",
          value: "12",
        },
      ];
    },
    tableColumns() {
      return [
        {
          scopedSlots: {
            customRender: "name",
          },
          // dataIndex: "mark",
          title: "原因",
          align: "center",
        },
        {
          dataIndex: "timeMoneyType",
          title: "类型",
          scopedSlots: {
            customRender: "type",
          },
          align: "center",
        },
        {
          title: "时间币数目",
          scopedSlots: {
            customRender: "timeCoin",
          },
          align: "center",
        },
        {
          title: '余额',
          dataIndex: 'balance',
          align: "center",
        },
        {
          dataIndex: "origin",
          title: "时间币来源/去向",
          align: "center",
        },
        {
          dataIndex: "recordTime",
          title: "操作时间",
          align: "center",
        },
      ];
    },
  },
  filters: {
    typeFilter(value) {
      // timeMoneyType 时间币类型, 1-商品兑换2-转账3-系统时间币4-其他5-参加活动
      switch(Number(value)) {
        case 1:
          return '商品兑换'
        case 2:
          return '转账'
        case 3:
          return '系统时间币'
        case 4: 
          return '其他'
        case 5:
          return '参加活动'
        default: 
        return '其他'
      }
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.stati-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 20px;
    div {
        span:nth-child(1) {
            margin-right: 5px;
        }
    }
}
</style>